import {useEffect, useState, useRef } from 'react';

const TitleAnime = ({title}) => {

  const myRef = useRef();
  const [inView, setInView] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setInView(entry.isIntersecting);
    })
    observer.observe(myRef.current);
  }, []);

  const titleArr = title.split("").map((char, index) => {
    const myChar = char === " " ? 
      <span key={index} className={inView ? 'span' : ''} >&nbsp;</span> 
      : 
      <span key={index} className={inView ? 'span' : ''}>{char}</span>
    ;
    return (myChar);
  });

  return (
    <div className='tAnime' ref={myRef}>
      {titleArr}
    </div>
  )
}

export default TitleAnime;