import {useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
library.add(faGithub, faLinkedin);

const SocialAnime = () => {

  const myRef = useRef();
  const [inView, setInView] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setInView(entry.isIntersecting);
    })
    observer.observe(myRef.current);
  }, []);

  return (
    <div className="social" ref={myRef}>
    {inView && <motion.div 
      className='social-anim'
      animate={{
        x: 0,
        opacity: 1 ,
        rotate: 360 
      }}
      initial={{
        opacity: 0,
        x: -200,
        y: 0
      }}
      transition={{
        type: "spring",
        stiffness: 100,
        damping: 90,
      }}
    > 
      <div className="circle">
        <a href="https://www.linkedin.com/in/farzanehsadegh/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon="fa-brands fa-linkedin" className='h-icon'/>
        </a>
      </div>
    </motion.div>}
    {inView && <motion.div 
      className='social-anim'
      animate={{
        y: 0,
        opacity: 1 ,
        rotate: 360 
      }}
      initial={{
        opacity: 0,
        y: 200
      }}
      transition={{
        type: "spring",
        stiffness: 100,
        damping: 90,
      }}
    > 
      <div className="circle">
        <a href="https://github.com/FarzanehSa/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon="fa-brands fa-github" className='h-icon'/>
        </a>
      </div>
    </motion.div>}
    </div>
  )
}

export default SocialAnime;