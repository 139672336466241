import { useEffect, useRef } from 'react';
import './About.scss';

const About = ({setPage}) => {

  const myRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) setPage("about")
    })
    observer.observe(myRef.current);
  }, []); // eslint-disable-line

  return (
    <div className="about" id="About">
      <div className="a-left">
        <div className="a-name">
          <span ref={myRef}>About me!</span>
          <span>I am full stack web developer based in Vancouver, Canada.</span>
          <span>I'm passionate about building dynamic and creative products from scratch and enjoy bringing ideas to life in the browser. </span>
          {/* <span>With experience working in agile environments, I possess an exceptional work ethic and excellent problem solving skills.</span> */}
          <span>Experienced in working under pressure and fast paced environments, maintaining great work ethics and excellent problem solving skills.</span>
          <span>I also have exceptional attention to detail while grasping a clear idea of the big picture.
          </span>
        </div>
        <a href="https://resume.creddle.io/resume/flqxpm1xfat" target="_blank" rel="noopener noreferrer">
          <button className="a-button">Check my CV</button>
        </a>
      </div>

      <div className="a-right">
        <div  className="a-image-wrapper">
          <img src="./photo3.png" alt=""/>
        </div>
        <div className="a-image-wrapper-blur"></div>
      </div>
    </div>
  )
}

export default About;