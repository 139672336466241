import {useEffect, useState} from 'react';
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {sanityClient} from './client.js';
import Navbar from './Components/Navbar/Navbar';
import About from './Components/About/About';
import Home from './Components/Home/Home';
import Skills from './Components/Skills/Skills';
import Projects from './Components/Projects/Projects';
import Contact from './Components/Contact/Contact';
import './App.scss';

function App() {

  const [showTopBtn, setShowTopBtn] = useState(false);
  const [page, setPage] = useState("home");
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    // start from top by refresh the page.
    window.scrollTo(0, 0);
    // when scroll to top button appear and navbar disappear
    window.addEventListener('scroll', () => {
      if (window.scrollY > 350) {
          setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });

    sanityClient.fetch(`*[_type == "project" && display == true]| order(id){
      title,
      desc,
      github,
      netlify,
      vServer,
      vClient,
      img,
      btnVClient,
      stack[]-> {
        title
    },
    }`)
    .then(data => setProjects(data))
    .catch(error => console.log(error))

  }, []);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  return (
    <div className="App">
      <Navbar showTopBtn={showTopBtn}/>
      <Home setPage={setPage}/>
      <About setPage={setPage}/>
      <Skills setPage={setPage}/>
      <Projects setPage={setPage} projects={projects}/>
      <Contact setPage={setPage}/>
      <div className='footer'>
        <span>Handcrafted by me, <FontAwesomeIcon icon="fa-solid fa-copyright" /> 2023</span>
      </div>
      <div className="top-to-btm">
        {showTopBtn && (
          <FontAwesomeIcon icon="fa-solid fa-circle-chevron-up" 
            className="icon-position icon-style"
            onClick={goToTop}
          />
        )}
      </div>
      <div className='page-select'>
        <ul style={{listStyleType: 'none'}}>
            <Link activeClass="active" to="Home" spy={true} smooth={true} offset={-150} duration={2000}>
              <li onClick={() => setPage("home")}>
                <FontAwesomeIcon icon="fa-solid fa-circle" className={page === "home" ? 'page-btn selected-page' : "page-btn"}/>
              </li>
            </Link>
            <Link activeClass="active" to="About" spy={true} smooth={true} offset={-60} duration={2000}>
            <li onClick={() => setPage("about")}>
                <FontAwesomeIcon icon="fa-solid fa-circle" className={page === "about" ? 'page-btn selected-page' : "page-btn"}/>
              </li>
            </Link>
            <Link activeClass="active" to="Skills" spy={true} smooth={true} offset={-60} duration={2000}>
              <li onClick={() => setPage("skills")}>
                <FontAwesomeIcon icon="fa-solid fa-circle" className={page === "skills" ? 'page-btn selected-page' : "page-btn"}/>
              </li>
            </Link>
            <Link activeClass="active" to="Projects" spy={true} smooth={true} offset={-60} duration={2000}>
              <li onClick={() => setPage("projects")}>
                <FontAwesomeIcon icon="fa-solid fa-circle" className={page === "projects" ? 'page-btn selected-page' : "page-btn"}/>
              </li>
            </Link>
            <Link activeClass="active" to="Contact" spy={true} smooth={true} offset={-60} duration={2000}>
              <li onClick={() => setPage("contact")}>
                <FontAwesomeIcon icon="fa-solid fa-circle" className={page === "contact" ? 'page-btn selected-page' : "page-btn"}/>
              </li>
            </Link>
          </ul>
      </div>
    </div>
  );
}

export default App;
