import { useEffect, useRef } from 'react';

import './Skills.scss';
import jquery from "../../img/jquery.png";
import resDes from "../../img/resDes.png";
import postgreSQL from "../../img/postgreSQL.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faReact, faSquareJs, faHtml5, faCss3Alt, faSass, faGit, faGithub } from "@fortawesome/free-brands-svg-icons";
library.add( faReact, faSquareJs, faHtml5, faCss3Alt, faSass, faGit, faGithub );


const Skills = ({setPage}) => {

  const skillsArr = [
    {name: "Java Script", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-square-js"/></span>},
    {name: "React", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-react" /></span>},
    {name: "HTML5", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-html5" /></span>},
    {name: "SASS", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-sass" /></span>},
    {name: "CSS", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-css3-alt" /></span>},
    {name: "jQuery", logo: <img src={jquery} alt="" />},
    {name: "PostgreSQL", logo: <img src={postgreSQL} alt="" />},
    {name: "Git", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-git" /></span>},
    {name: "GitHub", logo: <span className="s-circleIcon"><FontAwesomeIcon icon="fa-brands fa-github" /></span>},
    {name: "Responsive Design", logo: <img src={resDes} alt="" />},
  ];
  const myRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) setPage("skills")
    })
    observer.observe(myRef.current);
  }, []); // eslint-disable-line

  const skills = skillsArr.map((row, index) => {
    return (
      <div className='skill' key={index}>
        <div className='s-logo'>{row.logo}</div>
        <div className='s-name'>{row.name}</div>

      </div>
    )
  })

  return (
    <div className="skills" id="Skills">
      <div className="s-left">
        <div className="s-blur"></div>
        <span className="s-title" ref={myRef}>Skills</span>
        <div className="s-list">
          {skills}
        </div>
      </div>
      <div className="s-right">
        <div className="scene">
          <div className="cube">
            <div className="cube-face  cube-face-front">
              {skillsArr[0].logo}
            </div>
            <div className="cube-face  cube-face-back">
              {skillsArr[3].logo}
            </div>
            <div className="cube-face  cube-face-left">
              {skillsArr[2].logo}
            </div>
            <div className="cube-face  cube-face-right">
              {skillsArr[1].logo}
            </div>
            <div className="cube-face  cube-face-top">
              {skillsArr[6].logo}
            </div>
            <div className="cube-face  cube-face-bottom">
              {skillsArr[9].logo}
            </div>
          </div>
        </div>  
      </div>
    </div>
  )
}

export default Skills;