import { useState, useEffect, useRef } from "react";
import { motion } from 'framer-motion';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Projects.scss';

const Projects = ({setPage, projects}) => {

  const [isOpen, setOpen] = useState(false);
  const [url, setUrl] = useState();
  const myRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) setPage("projects")
    })
    observer.observe(myRef.current);
  }, []); // eslint-disable-line

/*   const projectsArr = [
    {
      name: "Salon Reservation Website",
      desc: "This is an app for beauty salons where customers can browse the services offered, stylists they prefer and book an appointment at any time they choose.\tAdmin will be able to control everything the website offers, from modifying services to adding new stylists and adjusting stylist's working hours and skills if needed.",
      github: 'https://github.com/FarzanehSa/salonOnlineAppointment-client',
      netlify: 'https://salon-online.netlify.app/',
      vServer:'https://res.cloudinary.com/demoshoebox/video/upload/v1673401679/portfolio/Salon-Admin_exla5o.mp4',
      vClient:'https://res.cloudinary.com/demoshoebox/video/upload/f_mp4/v1673401667/portfolio/Salon_rrsiru.mp4',
      stack: ["JavaScript", "React", "PostgreSql", "Sass", "Express"],
      img: 'https://res.cloudinary.com/demoshoebox/image/upload/v1673402105/portfolio/salon-png_hv4qgj.png',
      btnVClient: "Client Side demo",
    },
    {
      name: "Hangman",
      desc: `Classic Hangman Game. User can control background music and sounds.`,
      github: 'https://github.com/FarzanehSa/hangman-game',
      netlify: 'https://hangman-van.netlify.app/',
      vServer:'',
      vClient:'https://res.cloudinary.com/demoshoebox/video/upload/v1676416363/portfolio/Hangman_spinvd.mp4',
      stack: ["React", "Sass", "API", "JavaScript"],
      img: 'https://res.cloudinary.com/demoshoebox/image/upload/v1676416365/portfolio/Hangman_fre5jk.png',
      btnVClient: "User Demo",
    },
    {
      name: "The Shoe Box",
      desc: "E-commerce website for selling shoes with an admin dashboard to modify items, check inventory, check received orders and publish customer reviews.",
      github: 'https://github.com/FarzanehSa/shoeBox-client',
      netlify: 'https://shoe-box.netlify.app/',
      vServer:'https://res.cloudinary.com/demoshoebox/video/upload/v1673576078/portfolio/ShoeBox-Admin.mv4_mir9au.mp4',
      vClient:'https://res.cloudinary.com/demoshoebox/video/upload/v1673576085/portfolio/ShoeBox-Customer.mv4_v8cd3e.mp4',
      stack: ["JavaScript", "React", "PostgreSql", "Sass", "Express"],
      img: 'https://res.cloudinary.com/demoshoebox/image/upload/v1673576074/portfolio/ShoeBox_jjm1jq.png',
      btnVClient: "Customer Side demo",
    },
    {
      name: "Food-Pick-up-Ordering-app",
      desc: "A food ordering experience for a taco restaurant. Customers can visit the website, check the variety of items on the menu, select one or more dishes and order for pickup. Upon ordering, a message will be sent to the owner informing them that an order has been placed.",
      github: 'https://github.com/FarzanehSa/Food-Pick-up-Ordering-app',
      netlify: '',
      vServer:'',
      vClient:'',
      stack: ["HTML", "jQuery", "AJAX", "PostgreSql", "Sass", "Express"],
      img: 'https://res.cloudinary.com/demoshoebox/image/upload/v1673588769/portfolio/Food-Pickup_a7lzem.png',
      btnVClient: "",
    },
    {
      name: "Interview Scheduler",
      desc: "A scheduler that allows users to create, edit or delete interview appointments.",
      github: 'https://github.com/FarzanehSa/scheduler',
      netlify: 'https://interview-scheduler-van.netlify.app',
      vServer:'',
      vClient:'https://res.cloudinary.com/demoshoebox/video/upload/v1673589468/portfolio/Scheduler_arqlal.mp4',
      stack: ["HTML", "CSS", " React", "PostgreSql", "Express"],
      img: 'https://res.cloudinary.com/demoshoebox/image/upload/v1673589542/portfolio/Scheduler_o9ervy.png',
      btnVClient: "User Side demo",
    },
  ]; */

  const handleVideoPlay = (myUrl) => {
    setUrl(pre => myUrl);
    setOpen(pre => true);
  }

  return (
    <div className="projects" id="Projects">
      <ModalVideo 
        channel='custom' 
        autoplay 
        isOpen={isOpen} 
        url={url}
        // videoId="L61p2uyiMSo" 
        onClose={() => setOpen(false)} 
      />
      <span className="p-title" ref={myRef}>Projects</span>

      {projects && projects.map((row, index) => {
      // {projectsArr.map((row, index) => {
        return (
          <div className="p-container" key={index}>
            <div className="p-left-container">
              <motion.div 
                className="p-l-c"
                initial={{x:-100, opacity:0.1}}
                whileInView={{x:0, opacity:1}}
                transition={{type: "spring", stiffness: 60, damping: 60,}}
              >
                <img src={row.img} alt="" height="100"/>
              </motion.div>
            </div>
            <div className="p-right-container">
              <motion.div 
                className="p-r-c"
                initial={{x:100, opacity:0.1}}
                whileInView={{x:0, opacity:1}}
                transition={{type: "spring", stiffness: 60, damping: 60,}}
              >
                <span className="pro-title">{row.title}</span>
                {/* <span className="pro-title">{row.name}</span> */}
                <span className="pro-description">{row.desc}</span>
                <div className="p-buttons">
                  {row.vClient &&
                    <div>
                      {/* <span className="pro-video-text">Check:</span> */}
                      <button className="p-text-btn" onClick={()=> handleVideoPlay(row.vClient)}>{row.btnVClient}</button>
                    </div>
                  }
                  {row.vServer &&
                    <div>
                      <span className="pro-video-text">,</span>
                      <button className="p-text-btn" onClick={()=> handleVideoPlay(row.vServer)}>Admin side demo</button>
                    </div>
                  }
                </div>
                <div className="stack-group">
                  {row.stack && row.stack.map((r, i) => {
                    return(<span key={i} className="pro-stack">&nbsp; {r.title} &nbsp;</span>)
                  })}
                </div>
                <div className="p-buttons">
                  <a href={row.github} target="_blank" rel="noopener noreferrer">
                    <button className={row.netlify ? "p-button-1" : "p-button"}><FontAwesomeIcon icon="fa-solid fa-code" /></button>
                  </a>
                  {row.netlify && 
                    <a href={row.netlify} target="_blank" rel="noopener noreferrer">
                    <button className="p-button-2"><FontAwesomeIcon icon="fa-solid fa-eye" /></button>
                    </a>
                  }
                </div>
              </motion.div >
            </div>
          </div>
        )
      })}
      <div className="blur p-blur-1"></div>
      <div className="blur p-blur-2"></div>
      <div className="blur p-blur-3"></div>
    </div>
  )
}

export default Projects;