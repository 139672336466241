import TitleAnime from '../animations/TitleAnime';
import NameAnime from '../animations/NameAnime';
import SocialAnime from '../animations/SocialAnime';

import './Home.scss';

const Home = ({setPage}) => {

  return (
    <div className="home" id="Home">
        <div className="h-div">
        <NameAnime name="Farzaneh Akhoundsadegh" setPage={setPage}/>
        <TitleAnime title="Web Developer"/> 
        <SocialAnime />
      </div>
      <div className="blur h-blur-top"></div>
      <div className="blur h-blur-buttom"></div>
    </div>
  )
}

export default Home;