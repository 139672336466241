import { useEffect, useRef } from 'react';

const NameAnime = ({name, setPage}) => {

  const myRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) setPage("home");
    })
    observer.observe(myRef.current);
  }, []); // eslint-disable-line

  return (
    <span className='name-span' ref={myRef}>{name}</span>
  )
}

export default NameAnime;